<template>
	<div class="w-full h-full flex flex-col">
		<Header classes="items-end">
		<!-- <Header :classes="department && department.products && department.products.length && department.tasks && department.tasks.length ? 'items-end' : 'items-center'"> -->
			<div>
				<h1>Registrer</h1>
			</div>
			<!-- v-if="department && department.products && department.products.length && department.tasks && department.tasks.length" -->
			<Tabs 
				class="flex-none justify-center md:justify-start"
				:tabs="[
					{
						name: 'Produkter',
						alias: 'products',
					},
					{
						name: 'Oppgaver',
						alias: 'tasks',
					},
					// {
					// 	name: 'Kudos',
					// 	alias: 'kudos',
					// },
				]" 
				:active="entity" 
				@click="changeEntity( $event.alias )"
			/>
		</Header>
		
		<div 
			v-if="departmentId && departments && departments.length" 
			class="flex-grow overflow-hidden flex flex-col">
			
			<template v-if="entity == 'products' || entity == 'tasks'">
				<div class="flex-grow overflow-auto p-6 md:p-12">
					<TransactionCreate 
						:departmentId="departmentId" 
						@onTransactionChanged="transaction = $event"
						ref="TransactionCreateRef"
						:entity="entity"
						:key="'entity_'+entity"
					/>
				</div>
				
				<div class="flex-none px-6 py-6 flex space-x-4">
					<router-link 
						type="submit"
						:to="{ name: 'app-departments'}"
						:disabled="loading"
						class="button w-full">
						
						Avbryt
					</router-link>
					
					<button 
						v-if="
							transaction && 
							( (transaction.products && transaction.products.length) || (transaction.tasks && transaction.tasks.length) )
						"
						type="submit"
						@click.prevent="onCreateTransaction()" 
						:disabled="loading == 'creating'"
						class="button submit w-full"
						:class="{'loading' : loading == 'creating'}">
						
						Registrer
					</button>
				</div>
			</template>
			<!-- <template v-else-if="entity == 'kudos'">
				<div class="flex-grow overflow-auto p-6 md:p-12">
					<TransactionsListForKudosGiving 
						:department="department" 
						:user="signedInUser"
						:users="users"
					/>
				</div>
			</template> -->
		</div>
		<div v-else>
			<button 
				@click="onFetchDepartments"
				class="button submit">
				
				Last avdelinger på nytt
			</button>
		</div>
		
	</div>
</template>

<script>
	export default {
		components: {
			'TransactionCreate': () => import('@/components/transactions/TransactionCreate.vue'),
			'TransactionsListForKudosGiving': () => import('@/components/transactions/TransactionsListForKudosGiving.vue'),
			
			// 'TransactionsBrowse': () => import('@/components/transactions/TransactionsBrowse.vue'),
			// 'MessageList': () => import('@/components/messages/MessageList.vue'),
		},
		
		computed:{
			departments(){
				return this.$store.getters.getDepartments;
			},
			
			department(){
				if (!this.departments || !this.departments.length) {
					return null;
				}
				return this.departments.find( d => d.departmentId == this.departmentId);
			},
			
			users(){
				return this.$store.getters.getUsers;
			},
		},
		
		data(){
			return {
				departmentId: null,
				loading: null,
				transaction: null,
				entity: null, //'products'
			}
		},
		
		methods: {
			async onFetchDepartments(){
				await this.$store.dispatch('listDepartments');
			},
			
			async onCreateTransaction(){
				try {
					this.transaction.profile = this.department.profile || null;
					// console.log(this.transaction);
					
					try {
						if (this.signedInUser.attributes?.profile) {
							this.transaction.profile = JSON.parse( this.signedInUser.attributes?.profile );
						}
					} 
					catch (error) {
						console.error(error);
					}
					
					this.loading = 'creating';
					
					const response = await this.$store.dispatch('createTransaction', this.transaction);
					// console.log('response', response);
					
					this.transaction = null;
					// this.$notify({ type: 'success', text: 'Salg er registrert' });
					
					
					this.$store.commit('setShowConfetti', true);
					
					if ( this.signedInUserDepartments && this.signedInUserDepartments.length > 1 ) {
						this.$router.push( { name: 'app-departments' } );
					}
					else {
						 this.$refs.TransactionCreateRef.onReset();
					}
				} 
				catch (e) {
					this.$notify({ 
						type: 'error', 
						text: e.message || 'Kunne ikke registrere salg'
					});
				
					console.error(e);
				} 
				finally {
					this.loading = null;
				}
			},
			
			changeEntity( entity ){
				this.entity = entity;
				this.transaction = null;
			},
		},
		
		async mounted(){
			this.departmentId = this.$route.params.departmentId;
			await this.$store.dispatch('listCampaigns');
			
			if ( !this.$store.getters.getUsers ) {
				await this.$store.dispatch('listUsers');
			}
			
			if ( this.department && this.department.products && this.department.products.length) {
				this.entity = 'products';
			}
			else if ( this.department && this.department.tasks && this.department.tasks.length) {
				this.entity = 'tasks';
			}
		}
	}
</script>